import { render, staticRenderFns } from "./productRecords.vue?vue&type=template&id=be79ea9e&scoped=true"
import script from "./productRecords.vue?vue&type=script&lang=js"
export * from "./productRecords.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be79ea9e",
  null
  
)

export default component.exports