<template>
  <el-dialog title="农资" :visible="showRecordsFlag" width="60%" @close="closeDg" destroy-on-close :close-on-click-modal="false">
    <el-table v-if="records && records.length" ref="multipleTable" :data="records" stripe>
      <el-table-column prop="agricultureResourceTypeName" label="类型"> </el-table-column>
      <el-table-column prop="agriculturalProductionName" label="农资名称"> </el-table-column>
      <el-table-column label="用量">
        <template slot-scope="scope"> {{ getAbsAmount(scope.row.amount) }} {{ scope.row.unitOfMeasurementName }} </template>
      </el-table-column>
      <el-table-column prop="unitSpecs" label="规格"> </el-table-column>
    </el-table>
  </el-dialog>
</template>
<script>
export default {
  name: 'productRecords',
  props: {
    records: [],
    showRecordsFlag: false
  },
  data() {
    return {};
  },
  methods: {
    closeDg() {
      this.$emit('update:showRecordsFlag', false);
    },
    getAbsAmount(val) {
      return Math.abs(val);
    }
  }
};
</script>
<style lang="scss" scoped></style>
