<template>
  <el-dialog title="农机" :visible="showRecordsFlag" width="60%" @close="closeDg" destroy-on-close :close-on-click-modal="false">
    <div v-if="recordsItem && recordsItem.machineRecords && recordsItem.machineRecords.length">系统内农机</div>
    <el-table
      v-if="recordsItem && recordsItem.machineRecords && recordsItem.machineRecords.length"
      ref="multipleTable"
      :data="recordsItem.machineRecords"
      stripe
    >
      <el-table-column prop="machineTypeName" label="类型"> </el-table-column>
      <el-table-column prop="licensePlateNumber" label="车牌号">
        <template slot-scope="scope"> {{ scope.row.licensePlateNumber ? scope.row.licensePlateNumber : '-' }}</template>
      </el-table-column>
      <el-table-column prop="code" label="内部编码"> </el-table-column>
      <el-table-column prop="machineBelongTypeName" label="农机归属"> </el-table-column>
      <el-table-column prop="workArea" label="面积">
        <template slot-scope="scope"> {{ formatPlantArea(scope.row.workArea) }} 亩 </template>
      </el-table-column>
      <el-table-column prop="factoryName" label="厂商"> </el-table-column>
    </el-table>

    <div style="width: 100%; height: 20px"></div>
    <div v-if="recordsItem && recordsItem.machineOfOutsideRecords && recordsItem.machineOfOutsideRecords.length">系统外农机</div>
    <el-table
      v-if="recordsItem && recordsItem.machineOfOutsideRecords && recordsItem.machineOfOutsideRecords.length"
      ref="multipleTable"
      :data="recordsItem.machineOfOutsideRecords"
      stripe
    >
      <el-table-column prop="machineTypeName" label="类型"> </el-table-column>
      <el-table-column prop="contractName" label="联系人"> </el-table-column>
      <el-table-column prop="contractPhone" label="联系电话"> </el-table-column>
      <el-table-column prop="descriptions" label="备注"> </el-table-column>
      <el-table-column prop="workArea" label="面积">
        <template slot-scope="scope"> {{ formatPlantArea(scope.row.workArea) }} 亩 </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>
<script>
import * as utils from '@/utils/utils.js';
export default {
  name: 'productRecords',
  props: {
    records: [],
    showRecordsFlag: false,
    recordsItem: {}
  },
  data() {
    return {};
  },
  async mounted() {},
  methods: {
    formatPlantArea(val) {
      return utils.formatArea(val);
    },
    closeDg() {
      this.$emit('update:showRecordsFlag', false);
    }
  }
};
</script>
<style lang="scss" scoped></style>
