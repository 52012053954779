<template>
  <el-dialog :title="title" :visible="dialogVisible" width="60%" @close="closeMe" destroy-on-close :close-on-click-modal="false">
    <el-form :rules="rules" ref="ruleForm" :model="form" label-width="160px" :disabled="isDisable">
      <el-form-item label="企业名称" prop="name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="类型" prop="type">
        <el-select v-model="form.type" placeholder="请选择" :disabled="isEdit">
          <el-option v-for="item in machineType" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="企业编码" prop="customerCode">
        <el-input v-model="form.customerCode"></el-input>
      </el-form-item>
      <el-form-item label="企业社会信用代码" prop="companyId">
        <el-input v-model="form.companyId"></el-input>
      </el-form-item>
      <el-form-item label="法人姓名" prop="juridicalPerson">
        <el-input v-model="form.juridicalPerson"></el-input>
      </el-form-item>
      <el-form-item label="选择地址" prop="mapInfo">
        <el-row>
          <div v-if="mapInfo"> {{ mapInfo.pname }}/{{ mapInfo.cityname }}/{{ mapInfo.adname }}/{{ mapInfo.address }} </div>
          <el-button type="primary" @click="mapVisible = true">选择地址</el-button>
        </el-row>
      </el-form-item>
      <el-form-item label="详细地址" prop="address">
        <el-input v-model="form.address"></el-input>
      </el-form-item>
      <el-form-item label="种植作物" prop="descriptions">
        <el-input v-model="form.descriptions"></el-input>
      </el-form-item>
      <el-form-item label="联系方式" prop="contract">
        <el-input v-model="form.contract"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">{{ isEdit ? '修改' : '立即创建' }}</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </el-form-item>
    </el-form>
    <mapContainerVue :mapVisible.sync="mapVisible" @select="mapSelect"></mapContainerVue>
  </el-dialog>
</template>

<script>
import { businessAdd, businessUpdate } from '@/api/businessManage/api';
import mapContainerVue from '@/components/mapContainer.vue';
export default {
  props: {
    dialogVisible: false,
    isEdit: false,
    currentId: {},
    isDisable: false,
    machineType: []
  },
  components: {
    mapContainerVue
  },
  computed: {
    title() {
      let state = '新建';
      if (this.isEdit) {
        state = '编辑';
      }
      return state;
    }
  },
  data() {
    return {
      rules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        type: [{ required: true, message: '请选择类型', trigger: 'blur' }],
        address: [{ required: true, message: '请输入地址', trigger: 'blur' }],
        juridicalPerson: [{ required: true, message: '请输入法人姓名', trigger: 'blur' }],
        contract: [{ required: true, message: '请输入联系方式', trigger: 'blur' }],
        descriptions: [{ required: true, message: '请输入种植作物', trigger: 'blur' }],
        mapInfo: [
          {
            validator: this.validateFarmDate,
            required: true
          }
        ]
      },
      mapVisible: false,
      mapInfo: '',
      form: {
        contract: '',
        descriptions: '',
        address: '',
        juridicalPerson: '',
        companyId: '',
        customerCode: '',
        type: '',
        name: ''
      }
    };
  },
  created() {},
  destroyed() {},
  mounted() {},
  watch: {
    dialogVisible(val) {
      if (val) {
        this.form = {
          contract: '',
          descriptions: '',
          address: '',
          juridicalPerson: '',
          companyId: '',
          customerCode: '',
          type: '',
          name: ''
        };
        this.mapInfo = '';
        if (this.isEdit) {
          this.getDetail();
        }
      }
    }
  },
  methods: {
    validateFarmDate(rule, value, callback) {
      this.mapInfo ? callback() : callback(new Error(`请选择地址`));
    },
    mapSelect(e) {
      console.log(e);
      this.mapVisible = false;
      if (e) {
        this.mapInfo = e;
        this.form.address = e.name;
      }
    },
    getDetail() {
      this.form = _.cloneDeep(this.currentId);
      let res = _.cloneDeep(this.currentId);
      let mapI = {
        pname: res.province,
        lng: res.lon,
        lat: res.lat,
        cityname: res.city,
        adname: res.county
      };
      this.mapInfo = mapI;
    },

    closeMe(done) {
      this.$emit('update:dialogVisible', false);
    },
    onSubmit() {
      this.$refs['ruleForm']
        .validate((val) => {
          if (!val) return;

          let params = _.cloneDeep(this.form);
          params.province = this.mapInfo.pname;
          params.city = this.mapInfo.cityname;
          params.county = this.mapInfo.adname;
          params.longitude = this.mapInfo.lng;
          params.latitude = this.mapInfo.lat;
          // let formData = _.cloneDeep(this.form);
          if (this.isEdit) {
            businessUpdate(params)
              .then((e) => {
                this.$message({
                  message: '修改成功',
                  type: 'success'
                });
                this.closeMe();
                this.updateList();
              })
              .catch((error) => {});
          } else {
            businessAdd(params).then((e) => {
              this.$message({
                message: '创建成功',
                type: 'success'
              });
              this.closeMe();
              this.updateList();
            });
          }
        })
        .catch((error) => {});
    },

    updateList() {
      this.$emit('updateList');
    }
  }
};
</script>
<style scoped lang="scss"></style>
